import { CDataCard } from "src/components/card/CDataCard";
import { CDataCardBody } from "src/components/card/CDataCardBody";
import { CDataTypography } from "src/components/text/CDataTypography";
import { CDataSelectableAvatar } from "src/components/avatar/CDSelectableAvatar";
import { CDataTooltip, CDataTooltipType } from "src/components/CDataTooltip";
import classNames from "classnames";

export type PopularProduct = {
  key: string;
  title: string;
  isSelected: boolean;
  img: JSX.Element;
};

interface PopularProductsProps {
  productsTitle: string;
  products: PopularProduct[];
  onClickProduct: (name: string | undefined) => void;
  noColoring?: boolean;
  isUpdatePlanPage?: boolean;
}

export function PopularProducts(props: PopularProductsProps) {
  const {
    productsTitle,
    products,
    onClickProduct,
    noColoring,
    isUpdatePlanPage,
  } = props;

  const popularSourceAvatars = products.map((product) => (
    <CDataTooltip
      key={product.key}
      type={CDataTooltipType.Dark}
      title={product.title}
    >
      <div>
        <CDataSelectableAvatar
          className={classNames({
            "popular-source-avatar": !isUpdatePlanPage,
            "popular-source-avatar-plan-page": isUpdatePlanPage,
          })}
          id="popular-source-avatar"
          isSelected={product.isSelected}
          onClick={() => onClickProduct(product.key)}
        >
          {product.img}
        </CDataSelectableAvatar>
      </div>
    </CDataTooltip>
  ));

  return (
    <CDataCard
      noShadow={noColoring}
      className={classNames("popular-sources mb-0", {
        "no-coloring": noColoring,
      })}
    >
      <CDataCardBody
        className={classNames("d-flex", {
          "p-3 flex-column": !isUpdatePlanPage,
          "p-0 flex-row align-items-center gap-2": isUpdatePlanPage,
        })}
      >
        <CDataTypography
          variant="typography-variant-headline-5"
          className={classNames({ "pb-2": !isUpdatePlanPage })}
        >
          {productsTitle}
        </CDataTypography>
        <div
          className={classNames("popular-sources-row", {
            "gap-2": isUpdatePlanPage,
          })}
        >
          {popularSourceAvatars}
        </div>
      </CDataCardBody>
    </CDataCard>
  );
}
