import { Avatar, AvatarProps } from "@mui/material";
import classNames from "classnames";
import "./CDSelectableAvatar.scss";

interface CDataSelectableAvatarProps extends AvatarProps {
  isSelected: boolean;
}

export function CDataSelectableAvatar(props: CDataSelectableAvatarProps) {
  const { isSelected, className, ...remainingProps } = props;

  const classes = classNames("cd-selectable-avatar", className, {
    "is-selected": isSelected,
  });

  return (
    <Avatar {...remainingProps} className={classes}>
      {props.children}
    </Avatar>
  );
}
